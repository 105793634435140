table {
  &.table-dpws {
    &:not(.table-bordered) {
      thead {
        tr {
          th {
            border-top: none !important;
            padding: 7px;
          }
        }
      }
    }

    &.table-hover {
      tr.data-row {
        &:hover {
          background: transparent!important;
        }
      }
    }
    tbody {
      tr {
        &.data-row {
          >td {
            border-top: none;
            border-left-width: 20px;
          }
        }
          &.row-highlight {
              td {
                  background-color: rgba(152,251,152, .3)!important;
              }
          }

        &:first-child {
          td {
            border-top: 0 !important;
          }
        }

        &:last-child {
          td {
            border-bottom: 1px solid $theme-border;
          }
        }
      }
    }
    &.table-light {
      thead {
        tr {
          th {
            //color: #888;
          }
        }
      }

      tbody {
        tr {
          td {
            color: #888;
          }
        }
      }
    }
  }
  &.table-small {
    th, td {
      padding: 7px;
    }
  }
  &.table-xs {
    th, td {
      padding: 3px;
    }
  }


}
