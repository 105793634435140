
.project-view {
  .orders-card {
    .order-item {
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .price {
        margin-left: 5px;
        font-size: 12px;
      }

      .status {
      }
    }
  }
}

.project-categories {
  $children-offset: 25px;

  .project-category {
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    .category-dropzone {
      min-height: 5px;


      &.available {
        //background-color: #efefef;
      }

      &.active {
        background-color: #dfdfdf;
        padding-left: 5px;
        min-height: 25px;
        font-size: 11px;
        vertical-align: middle;
        line-height: 25px;
      }

    }

    .header {
      .drag-handle {
        cursor: move;
        display: none;
        margin-right: 5px;
      }

      .name {
        font-weight: bold;
      }

      .controls {
        margin-left: 10px;
        //display: none;

        .control {
          cursor: pointer;
          margin-right: 3px;

          &:hover {
            color: $primary-color;
          }
        }
      }

      &:hover {
        .drag-handle {
          display: inline-block;
        }

        .controls {
          display: inline-block;
        }
      }
    }

    > .orders {
      .category-order {
        position: relative;
        border-left: $children-offset solid transparent;
        margin-bottom: 0;
        border-bottom: 0;

        > .header {
          span {
            margin-right: 5px;
          }
        }


        &:last-child {
          padding-bottom: 0;
        }

        &:after {
          content: '';
          position: absolute;
          top: 11px;
          left: - $children-offset + 10px;
          display: block;
          width: $children-offset - 12px;
          border-bottom: 1px dashed $primary-color;
          border-left: 1px dashed $primary-color;
          z-index: 2;
        }

        &:before {
          content: '';
          position: absolute;
          top: -5px;
          bottom: 5px;
          left: - $children-offset + 10px;
          display: block;
          //height: 100%;
          width: $children-offset;
          border-left: 1px dashed $primary-color;
          z-index: 2;
        }

        &:last-child {
          &:before {
            height: 16px;
          }
        }
      }
    }

    > .children {
      //margin-left: -15px;
      //margin-top: 7px;
      position: relative;
      z-index: 10;
      .project-category {

        border-left: $children-offset solid transparent;
        margin-bottom: 0;
        border-bottom: 0;
        >.header {
          position: relative;
          background-color: white;
          z-index: 100;
        }

        &:last-child {
          padding-bottom: 0;
        }

        &:after {
          content: '';
          position: absolute;
          top: 11px;
          left: - $children-offset + 10px;
          display: block;
          width: $children-offset - 12px;
          border-bottom: 1px dashed $primary-color;
          border-left: 1px dashed $primary-color;
          z-index: 2;
        }

        &:before {
          content: '';
          position: absolute;
          top: -5px;
          bottom: 5px;
          left: - $children-offset + 10px;
          display: block;
          //height: 100%;
          width: $children-offset;
          border-left: 1px dashed $primary-color;
          z-index: 2;
        }

        &:last-child {
          &:before {
            height: 16px;
          }
        }
      }
    }
  }

  .project-category-add {
    cursor: pointer;

    &:hover {
      color: $primary-color;
    }
  }
}