.dpws-custom-table {

  thead {
    th {
      .sortable {
        color: $primary-color;
        cursor: pointer;
        user-select: none;
      }
      .grouping {
        color: $secondary-color;
        cursor: pointer;
        user-select: none;
        margin-left: 10px;
        &.active {
          color: $warning-color;
        }
      }
    }
  }
  tbody {
    tr {
      &.ct-group-header {
        background-color: lighten($secondary-color, 45%);
      }
    }
  }

  .ct-configurator {
    .ct-body {

      .ct-column-definition {
        height: 30px;
        line-height: 30px;
        vertical-align: middle;
        padding-left: 10px;
        border: 1px solid $theme-border;
        margin-bottom: -1px;
        background-color: white;

        &.active {
          font-weight: bold;
          cursor: move;

          &:hover {
            background-color: lighten($layout-6-bg-color, 45%);
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}