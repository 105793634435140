.dpws-user-widget {
  vertical-align: middle;
  display: inline-block;
  width: auto;
  .user-avatar {
    float: left;
  }
  .username {
    margin-left: 45px;
    line-height: 20px;
  }
}



.user-status-icons {
  i {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.user-main-info {
  .user-status-label {
    .badge {
      padding: 5px;
      margin-bottom: 15px;
    }
  }

  .user-email {
    button {
      margin-top: 5px;
    }
  }
  .user-avatar {
    position: relative;
    margin-bottom: 10px;

    .change-avatar {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: 0;
    }

    &:hover {
      .change-avatar {
        display: inline;
      }
    }
  }
}