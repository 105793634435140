.print-only {
    display: none !important;
}

@media print {
    .noprint {
        display: none !important;
    }

    .print-only {
        display: initial !important;
    }

    .table-responsive {
        overflow-x: visible;
    }
}
