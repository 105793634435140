.mailing-templates {
  .mailing-template {
    color: $interactive-color;
    margin-right: 7px;
    margin-bottom: 5px;
    cursor: pointer;

    &:hover {
      color: $interactive-color-active;
    }
  }
}