.dpws-form {
  .dadata-search {
      margin-bottom: 10px;
    margin-top: -10px;
  }

  .form-row {
    label {
      overflow-x: hidden;
      white-space: nowrap;
      margin-bottom: 0px;
    }
    .input-group-sm {
      margin-bottom: 10px;
    }
  }
  .checkbox {
    overflow-y: hidden;
  }
  .ant-calendar-picker {
    &.form-control {
      padding: 0;
      font-size: .875rem;
      line-height: 1.5;
      border-radius: 0;
      background: transparent!important;
      border: none;
    }
    .form-control {
      &.ant-calendar-picker-input {
        padding: 4px 8px;
        background-color: #f4f7fa;
      }
    }
    .ant-calendar-picker-icon {
      display: none;
    }
  }

  .ant-select {
    &.form-control {
      font-size: .875rem;
      line-height: 1.5;
      min-height: 30px;
      height: auto;
      padding: 0;
      //border-radius: 0;
      background-color: #f4f7fa;
      background-image: none;
      //border: none;
      .ant-select-arrow {
        right: 10px;
      }
      .ant-select-selector {
        background: transparent!important;
        border-radius: inherit;
        border: none!important;
        min-height: 26px;
          height: 30px;
        margin: 0;
        &--multiple {
          padding-bottom: 0;
          margin: -2px 0;
        }
        &--single {
          min-height: 20px;
          height: auto;
        }
        &__rendered {
          line-height: inherit;
          margin-left: 0;
          margin-right: 0;
          > ul {
            >li {
              height: 21px;
              margin-top: 3px;
              line-height: 19px;


            }
          }
        }
        &__choice {
          border: 1px solid $secondary-color;
          &__remove {
            line-height: 16px;
            vertical-align: middle;
          }
        }
        .ant-select-arrow {
          margin-right: .5rem;
        }
      }
    }

  }
}
.ant-select-dropdown {
  z-index: 10000;
}

.field-label {
    font-weight: bold;
}
