


.contractors {
  .contractor-row {
    td.badges {
      width: 0;
      font-size: 16px;
      vertical-align: middle;
      text-align: center;
      .badge {
        margin-right: 3px;
        cursor: default;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.contractor-view {
  .contractor-types {
    .type-badge {
      font-size: 12px;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.right-card {
  overflow: visible !important;

  .add-btn {
    font-size: 16px;
    float: right;
    cursor: pointer;
    color: white;
    background-color: $primary-color;
    display: inline-block;
    height: 24px;
    width: 24px;
    border-radius: .25rem;

    &:hover {
      background-color: $info-color;
    }
  }

  .card-list {
    .card-item {
      position: relative;
      border-bottom: 1px solid rgba(0, 0, 0, .05);
      margin-top: 5px;
      padding-bottom: 10px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      .control {
        position: absolute;
        right: 0;
        top: 0;
        display: inline-block;
        padding: 5px 5px 0 0;

        .toggler {
          display: inline-block;
          cursor: pointer;

          &:hover {
            color: black;
          }
        }

      }
    }

    .card-add-btn {
      text-align: center;
      cursor: pointer;
      margin-top: 10px;

      i {
        font-size: 24px;
      }

      &:hover {
        color: darken($theme-font-color, 30%);
      }
    }
  }

  .managers {
    .manager {
      .comment {
        margin-top: 5px;
        color: rgba(0, 0, 0, 0.3);
        font-size: 13px;
      }
    }
  }

  .accounts {
    .account {
      .comment {
        color: rgba(0, 0, 0, 0.3);
        font-size: 13px;
      }
    }
  }

  .contacts {
    .contact {
      .name {
        font-weight: bold;
        font-size: 15px;
        text-align: center;
      }

      .rank {
        text-align: center;
        color: rgba(0, 0, 0, 0.3);
        font-size: 13px;
        font-weight: bold;
      }

      .comment {
        color: rgba(0, 0, 0, 0.3);
        font-size: 13px;
      }

      .communication {
        font-size: 13px;

        span.type {
          font-weight: bold;
          margin-right: 3px;
        }
      }
    }
  }
}