.dpws-entity-list {
  table {
    thead {
      th {
        &.sortable {
          color: $primary-color;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }
}