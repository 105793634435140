  iframe {
    &.email-display {

      display: block;
      border: none;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 0;
      min-height: 0;
    }
  }