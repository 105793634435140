.dpws-value-table {
  tr {
    &:hover {
      background-color: rgba(0,0,0,0.03);
    }
    td {
      padding-bottom: 5px;
      vertical-align: top;
    }
    td:first-child {
      font-weight: bold;
      padding-right: 30px;
      white-space: nowrap;
    }
  }
}