.card {
  .card-block {
    position: relative;

    .card-lock {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.1);
      color: white;
      text-align: center;
    }
  }
}

.break-after {
  break-after: right;
}

.page-sections {
  .nav-pills {
    box-shadow: none;
    padding: 0;

    .nav-item {
      .nav-link {
        padding: 5px 10px;
        cursor: pointer;
      }
    }
  }
}

.btn-xs {
  > i {
    margin-right: 3px;
  }
}

.highlight {
  padding: 0;
  font-weight: bold;
  background-color: rgba(19, 181, 227, 0.2);
}

.va-center {
  vertical-align: middle !important;
}

.va-top {
  vertical-align: top !important;
}

.va-bottom {
  vertical-align: bottom !important;
}

.debug-toggle {
    background-color: white;
    border-radius: 12px;
    padding: 0 3px;
    box-shadow: 0px 0px 5px 5px white;
}

.header-icons li a {
    @media (max-width: 991px) {
        color: gray !important;
    }
}

.ant-message {
    z-index: 99999!important;
}

.header-logo-2 {
    height: 45px!important;
    margin-left: 30px;
    @media (max-width: 991px) {
        display: none;
    }
}

.float-right {
    float: right;
}
