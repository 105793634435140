.catalog {
    .catalog-desired {
        padding: 1px 10px;
        &.inactive {
            cursor: pointer;
            color: $theme-font-color;
            &:hover {
                color: $primary-color;
            }
        }
        &.active {
            color: $primary-color;
        }
    }
}
