.dpws-infoblock {
  >.heading {
    font-size: 18px;
    font-weight: 400;
    color: #111;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 10px;

    .btn {
      height: 24px;
      line-height: 24px;
      vertical-align: middle;
      padding: 0 10px;
      font-size: 14px;
      margin-bottom: 0;
      i {
        margin-right: 5px;
      }
    }
  }
  >.body {
    padding: 15px 0;
  }
}