.main-navigation {
    overflow-x: hidden !important;
}

body {
    overflow-x: hidden;
}

body .text-bg-info {
    background-color: $info-color !important;
    color: white !important;
}

.btn-block {
    display: block;
}

.text-right {
    text-align: right !important;
}

.ant-pagination-options-size-changer {
    display: none !important;
}

.ta-autosize {
    min-height: 32px;
    resize: none;
}

.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
    line-height: 26px;
}

.ant-timeline-item-last {
    padding-bottom: 0;

    > .ant-timeline-item-content {
        min-height: 0;
    }
}

.ant-checkbox-wrapper {
    overflow-y: hidden;
}

.ant-select-auto-complete {
    input {
        border: none;
        padding: 0;
        height: 20px !important;

        &:hover {
            border-color: transparent !important;
        }
    }
}

.ant-calendar-picker-container {
    z-index: 2000 !important;
}

.ant-picker-dropdown {
    z-index: 10000 !important;
}

body {
    background: $primary-color !important;
}

.navbar-brand {
    .b-brand {
        .b-img {
            height: 45px !important;
        }
    }
}

.m-header {
    .b-brand {
        .b-img {
            height: 35px !important;
        }
    }
}

.dpws-modal {
    border: none;

    .modal-backdrop {
        background-color: rgba(0, 0, 0, 0.3);
        animation-duration: 0.3s;
    }

    .modal-content {
        &.animated {
            animation-duration: 0.35s;
        }
    }
}

li.pcoded-hasmenu .pcoded-submenu {
    padding: 5px 0 !important;
    display: block !important;
}

.mobile-menu.on span {
    background: transparent !important;
}

.input-group-sm {
    select {
        height: calc(1.5em + .5rem + 2px) !important;
    }
}

a.fc-daygrid-day-number, a.fc-col-header-cell-cushion {
    color: $theme-font-color;
}

.drp-user {
    &:before {
        display: none !important;
    }

    .dropdown-toggle {
        &:after {
            display: none;
        }
    }

    &.show {
        &:before {
            display: none !important;
        }

        .dropdown-toggle {
            &:after {
                display: none;
            }
        }
    }
}

.profile-notification {
    display: block;
    overflow: visible !important;
    margin-right: 20px !important;

    &:before {
        display: block !important;
        content: "";
        font-family: "pct";
        position: absolute;
        right: 21px;
        top: -4px;
        z-index: 1001;
        font-size: 40px;
        line-height: 0;
        color: #7450A9;
        text-shadow: 0 -2px 2px rgba(65, 76, 78, 0.12);
    }

    .pro-head {
        background: linear-gradient(to right, rgb(102, 126, 234) 0%, rgb(118, 75, 162) 100%) !important;
    }
}

.card {
    overflow: hidden;

    .animated {
        border: none;
    }

    .card-header {
        overflow: hidden;

        &.dark {
            h5 {
                &:after {
                    background-color: rgb(118, 75, 162) !important;
                }
            }
        }

        &.silent {
            h5 {
                &:after {
                    background-color: transparent !important;
                }
            }
        }
    }

    &-block {
        flex: 1;
    }
}

.error-page {
    .error-code {
        font-size: 200px;
        background: linear-gradient(to right, rgb(102, 126, 234) 0%, rgb(118, 75, 162) 100%);
        background-clip: text;
        color: transparent;
        font-weight: bold;
    }
}

@media only screen and (max-width: 991px) {
    .profile-notification {

        &:before {
            right: 42px;
        }
    }
}

@media only screen and (max-width: 575px) {
    .profile-notification {
        margin-right: auto !important;

        &:before {
            right: 7px;
        }
    }
    .error-page {
        .error-code {
            font-size: 100px;
        }
    }
}


.form-control.is-invalid, .was-validated .form-control:invalid,
.form-control.is-valid, .was-validated .form-control:valid {
    background-position-y: center !important;
    background-position-x: calc(100% - 5px) !important;
}

.ant-picker {
    .ant-picker-suffix {
        display: none;
    }
}

.card-header {
    .controls {
        display: inline-block;
        float: right;

        .ant-picker-input {
            width: 75px;
        }

        .ant-picker-range-separator {
            padding: 0 3px !important;
            line-height: 10px;
            vertical-align: middle;

            svg {
                margin-top: -5px;
            }
        }
    }
}

.navbar-nav {
    .fa {
        color: white;

        :hover {
            color: white;
        }
    }
}

.fc-button-group {
    .fc-button {
        &.fc-button-primary {
            background-color: white !important;
            border: 1px solid $secondary-color !important;
            color: $theme-font-color !important;

            &.fc-button-active, &.fc-prev-button, &.fc-next-button {
                background-color: $primary-color !important;
                border: 1px solid $primary-color !important;
                color: white !important;
            }
        }

        &:focus {
            box-shadow: none !important;
        }
    }

}

.fc-button {
    font-size: 13px !important;

    &.fc-button-primary {
        background-color: $primary-color !important;
        border: 1px solid $primary-color !important;
        color: white !important;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.fc-event-main {
    cursor: pointer !important;
}


.search-picker {
    display: flex;

    .ant-picker-range {
        flex: 1;
        background-color: #f4f7fa;
        padding: 4px;
    }

    .reset-btn {
        margin: 0 0 0 7px;
    }
}

.ant-dropdown {
    &:has(> .ant-dropdown-menu) {
        z-index: 15000;
    }
}

.ant-tooltip {
    z-index: 15000;
}

.wide-transfer .ant-transfer-list:first-child {
    flex: none;
    width: 50%;
}

.antd-vertical-cb-group {
    .ant-checkbox-group {
        display: flex;
        flex-direction: column;
    }
}

.block-confirm {
    display: block;
    height: 100%;
    width: 100%;
}
