.notifications {
    .notification {
        span {
            margin-right: 5px;
        }
    }

    .ant-timeline-item {
        padding-bottom: 5px;
    }
}

.notifications-list {
    .dpws-entity-list {
        tr.unread {
            font-weight: bold;

            td {
                color: #666;
            }
        }

        .mark-as-read {
            cursor: pointer;
        }
    }
}
