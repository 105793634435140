@media print {
    .task-view {
        .task-contractors {
            .task-contractor {
                .header {
                    text-align: left !important;

                    .name {
                        font-weight: bold;
                        display: inline;
                    }

                    .group {
                        display: inline;
                    }
                }
            }
        }
    }
}


.task-view {
    .task-contractors {
        .task-contractor {
            margin-right: -.5rem;
            margin-left: -.5rem;
            padding-right: .5rem;
            padding-left: .5rem;
            margin-bottom: 7px;
            padding-bottom: 7px;
            border-bottom: 1px solid #f1f1f1;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }


            .header {
                text-align: left;

                .name {
                    font-weight: bold;

                    .group {
                        font-weight: normal;
                    }

                    .toggler {
                        float: right;
                        cursor: pointer;
                    }
                }
            }

            .extra-fields {
                .extra-field {
                    display: inline-block;
                    margin-right: 10px;
                    break-inside: avoid;
                }
            }
        }
    }
}
