.orders {
  tr.order-row {
    .number {
      font-weight: bold;
    }
    .date {
      font-size: 12px;
      color: $secondary-color;
    }
  }
}
