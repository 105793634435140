.structure-tree {
  $children-offset: 25px;

  .structure-node {
    position: relative;

    &.boxed {
      .header {
        border: 1px solid #AAA;
        display: inline-block;
        padding: 5px;
        margin-bottom: 10px;
      }

      > .children {
        //margin-left: -15px;
        //margin-top: 7px;
        position: relative;
        z-index: 10;

        .structure-node {
          &:before {
            top: -8px;
            bottom: 5px;
          }
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }

    .header {

      .name {
        font-weight: bold;
      }

      .controls {
        margin-left: 10px;

        .control {
          cursor: pointer;
          margin-right: 3px;
          color: $subtext-color;

          &:hover {
            color: $primary-color;
          }
        }
      }

      &:hover {
        .controls {
          display: inline-block;
        }
      }
    }

    > .children {
      //margin-left: -15px;
      //margin-top: 7px;
      position: relative;
      z-index: 10;
      .structure-node {

        border-left: $children-offset solid transparent;
        margin-bottom: 0;
        border-bottom: 0;
        >.header {
          position: relative;
          background-color: white;
          z-index: 100;
        }

        &:last-child {
          padding-bottom: 0;
        }

        &:after {
          content: '';
          position: absolute;
          top: 11px;
          left: - $children-offset + 10px;
          display: block;
          width: $children-offset - 12px;
          border-bottom: 1px dashed $primary-color;
          border-left: 1px dashed $primary-color;
          z-index: 2;
        }

        &:before {
          content: '';
          position: absolute;
          top: -5px;
          bottom: 5px;
          left: - $children-offset + 10px;
          display: block;
          //height: 100%;
          width: $children-offset;
          border-left: 1px dashed $primary-color;
          z-index: 2;
        }

        &:last-child {
          &:before {
            height: 16px;
          }
        }
      }
    }
  }

}